<template>
	<div id="embed">
		<b-container v-if="asset" fluid class="m-0 p-0">
            <b-embed type="iframe" :src="url()" allowfullscreen></b-embed>
		</b-container>
	</div>
</template>
<script>
	export default {
		name: 'Embed',
		computed: {
			asset() {
				return this.$store.getters.asset
			},
            env() {
				return process.env
			},
		},
        methods: {
            url() {
				if (this.$route.query.autostart) {
					return `${this.env.VUE_APP_PLAYER_SRC}?api_key=${this.env.VUE_APP_API_KEY}&asset_key=${this.asset.asset_key}&fetch=true&autoplay=${this.$route.query.autostart}`;
				}
				return `${this.env.VUE_APP_PLAYER_SRC}?api_key=${this.env.VUE_APP_API_KEY}&asset_key=${this.asset.asset_key}&fetch=true`;
            }
        },
		mounted() {
			this.$store.dispatch('embedd', { slug: this.$route.params.slug})
			.catch(err => {
				console.log(err)
			})
		}
	}
</script>
