<template>
	<div id="assets" v-if="filtered && filtered.length > 0">
		<b-row>
			<b-col cols="12" lg="4" class="pb-4" v-for="asset in filtered" :key="asset.id">
				<div class="item" @click="open(asset)">
					<div class="video" v-if="activeAsset && activeAsset.id === asset.id">
						<div class="embed-responsive embed-responsive-16by9 bg-black">
							<iframe class="embed-responsive-item" :src="getVideoSrc(activeAsset)" allowfullscreen="allowfullscreen"></iframe>
						</div>
					</div>
					<div class="img" v-else>
						<img :src="asset.thumbnail_url" v-if="asset.thumbnail_url"/>
						<p class="placeholder m-0" v-else>No image</p>
					</div>
					<div class="text">
						<h3>{{ truncate(asset.title, 45) }}</h3>
						<p v-html="truncate(asset.description, 100)" class="m-0" v-if="asset.description"></p>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				isMobile: false
			}
		},
		computed: {
			activeAsset() {
				return this.$store.getters.activeAsset
			},
			asset() {
				return this.$store.getters.asset
			},
			assets() {
				return this.$store.getters.assets
			},
			env() {
				return process.env
			},
			filtered() {
				return this.$route.name == 'category' 
				? this.pinnedAsset ? this.assets.filter(a => a.id != this.pinnedAsset.id) : this.assets
				: this.pinnedAsset ? this.assets.filter(a => a.id != this.asset.id && a.id != this.pinnedAsset.id) : this.assets.filter(a => a.id != this.asset.id)
			},
			password() {
				return this.$store.getters.password
			},
			pinnedAsset() {
				return this.$store.getters.pinnedAsset
			}
		},
		methods: {
			open(asset) {
				if (this.isMobile) {
					this.$store.dispatch('activeAsset', asset)
					this.$store.dispatch('modalAsset', null)
				} else {
					this.$bvModal.show('asset-modal')
					this.$store.dispatch('modalAsset', asset)
					this.$store.dispatch('activeAsset', null)
				}
			},
			getVideoSrc(asset) {
				return asset.type === 4 && asset.broadcasting !== 3
				? this.env.VUE_APP_PLAYER_SRC + '?api_key=' + this.env.VUE_APP_API_KEY +'&slug=' + asset.slug + '&fullscreen=true&fetch=true&live=true'
				: this.env.VUE_APP_PLAYER_SRC + '?api_key=' + this.env.VUE_APP_API_KEY +'&slug=' + asset.slug + '&fullscreen=true&fetch=true'
			},
			truncate(str, length) {
				if(this.isMobile) {
					return str
				} else {
					if(str.length > length) {
						return str.substring(0, length) + '...'
					} else {
						return str
					}
				}
			}
		},
		mounted() {
			const ua = this.$browserDetect.meta.ua
			this.isMobile = ua.includes('Mobile') ? true : false
		}
	}
</script>
