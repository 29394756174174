var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { id: "asset", cols: "12", lg: _vm.pinnedAsset ? "8" : "12" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "pb-4",
              attrs: { cols: "12", lg: _vm.pinnedAsset ? "12" : "8" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "item",
                  on: {
                    click: function($event) {
                      return _vm.open(_vm.asset)
                    }
                  }
                },
                [
                  (_vm.isMobile &&
                    _vm.activeAsset &&
                    _vm.asset.id === _vm.activeAsset.id) ||
                  !_vm.isMobile
                    ? _c("div", { staticClass: "video" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "embed-responsive embed-responsive-16by9 bg-black"
                          },
                          [
                            _c("iframe", {
                              staticClass: "embed-responsive-item",
                              attrs: {
                                src: _vm.videoSrc,
                                allowfullscreen: "allowfullscreen"
                              }
                            })
                          ]
                        )
                      ])
                    : _c("div", { staticClass: "img" }, [
                        _vm.asset.thumbnail_url
                          ? _c("img", {
                              attrs: { src: _vm.asset.thumbnail_url }
                            })
                          : _c("p", { staticClass: "placeholder m-0" }, [
                              _vm._v("No image")
                            ])
                      ])
                ]
              )
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "pb-4",
              attrs: { cols: "12", lg: _vm.pinnedAsset ? "12" : "4" }
            },
            [
              _c("h2", [_vm._v(_vm._s(_vm.asset.title))]),
              _vm.asset.description
                ? _c("p", {
                    staticClass: "m-0",
                    domProps: { innerHTML: _vm._s(_vm.asset.description) }
                  })
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }