<template>
	<div id="category" v-if="category">
		<b-container fluid>
			<b-row>
				<Asset :asset="headingAsset" v-if="headingAsset"/>
				<PinnedAsset :asset="pinnedAsset" v-if="pinnedAsset"/>
			</b-row>
			<Assets v-if="assets"/>
			<div class="d-flex justify-content-center" role="status" v-else>
				<b-spinner label="Loading..."></b-spinner>
			</div>
			<Modal/>
		</b-container>
	</div>
	<div v-else-if="error">
		<b-container fluid>
			<b-row>
				<b-col class="text-center mt-5">
					<h1 class="display-1">{{error}}</h1>
				</b-col>
			</b-row>	
		</b-container>
	</div>
</template>
<script>
	import Asset 		from '@/components/Asset.vue'
	import Assets 		from '@/components/Assets.vue'
	import PinnedAsset 	from '@/components/PinnedAsset.vue'
	import Modal 		from '@/components/Modal.vue'
	export default {
		name: 'Category',
		metaInfo() {
			return {
				title: this.category ? this.category.title : ''
			}
		},
		data() {
			return {
				headingAsset: null,
				error: null
			}
		},
		components: {
			Asset,
			Assets,
			PinnedAsset,
			Modal
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			assets() {
				return this.$store.getters.assets
			},
			category() {
				return this.$store.getters.category
			},
			loading() {
				return this.$store.getters.loading
			},
			modalAsset() {
				return this.$store.getters.modalAsset
			},
			password() {
				return this.$store.getters.password
			},
			pinnedAsset() {
				return this.$store.getters.pinnedAsset
			},
			type() {
				return this.$store.getters.type;
			}
		},
		mounted() {
			this.$store.dispatch("type", this.$route.path.split('/').pop())
				.then(() => {
					return this.$store.dispatch('category', { slug : this.$route.path.split('/').pop(), password: this.password })
				})		
				.then(response => {
					return this.$store.dispatch('assets', { category_id: response.id, password: this.password })
				})
				.then(response => {
					this.headingAsset = response.shift();
				})
				.catch(err => {
					this.error = err.response ? err.response.status : 500;
				})
		}
	}
</script>
