<template>
	<b-col id="asset" cols="12" :lg="pinnedAsset ? '8' : '12'">
		<b-row>
			<b-col cols="12" :lg="pinnedAsset ? '12' : '8'" class="pb-4">
				<div class="item" @click="open(asset)">
					<div class="video" v-if="(isMobile && activeAsset && asset.id === activeAsset.id) || !isMobile">
						<div class="embed-responsive embed-responsive-16by9 bg-black">
							<iframe class="embed-responsive-item" :src="videoSrc" allowfullscreen="allowfullscreen"></iframe>
						</div>
					</div>
					<div class="img" v-else>
						<img :src="asset.thumbnail_url" v-if="asset.thumbnail_url"/>
						<p class="placeholder m-0" v-else>No image</p>
					</div>
				</div>
			</b-col>
			<b-col cols="12" :lg="pinnedAsset ? '12' : '4'" class="pb-4">
				<h2>{{ asset.title }}</h2>
				<p class="m-0" v-html="asset.description"  v-if="asset.description"></p>
			</b-col>
		</b-row>
	</b-col>
</template>
<script>
	export default {
		data() {
			return {
				videoSrc: null,
				isMobile: false
			}
		},
		props: {
			asset: {
				default: () => {
					return null
				}
			}
		},
		computed: {
			activeAsset() {
				return this.$store.getters.activeAsset
			},
			env() {
				return process.env
			},
			loading() {
				return this.$store.getters.loading
			},
			src() {
				return this.env.VUE_APP_PLAYER_SRC + '?api_key=' + this.env.VUE_APP_API_KEY + '&slug=' + this.asset.slug + "&fetch=true"
			},
			pinnedAsset() {
				return this.$store.getters.pinnedAsset
			}
		},
		methods: {
			open(asset) {
				if (this.isMobile) {
					this.$store.dispatch('activeAsset', asset)
					this.$store.dispatch('modalAsset', null)
				}
			}
		},
		mounted() {
			const ua = this.$browserDetect.meta.ua
			this.isMobile = ua.includes('Mobile') ? true : false

			this.videoSrc = this.src
			this.$root.$on('bv::modal::show', () => {
				this.videoSrc = null
			})
			this.$root.$on('bv::modal::shown', () => {
				this.videoSrc = this.src
			})
		}
	}
</script>
