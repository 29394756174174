var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "text-center",
      attrs: {
        id: "asset-modal",
        "header-bg-variant": "dark",
        "header-text-variant": "light",
        centered: "",
        "hide-footer": "",
        size: "lg"
      }
    },
    [
      _vm.modalAsset
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "embed-responsive embed-responsive-16by9 bg-black"
                  },
                  [
                    _c("iframe", {
                      staticClass: "embed-responsive-item",
                      attrs: {
                        src: _vm.src,
                        allowfullscreen: "allowfullscreen"
                      }
                    })
                  ]
                )
              ]),
              _c("b-col", { staticClass: "py-4 px-5", attrs: { cols: "12" } }, [
                _c("h2", [_vm._v(_vm._s(_vm.modalAsset.title))]),
                _c("p", {
                  staticClass: "m-0",
                  domProps: { innerHTML: _vm._s(_vm.modalAsset.description) }
                })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }