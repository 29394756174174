<template>
	<b-modal 	id="asset-modal"
				header-bg-variant="dark"
				header-text-variant="light"
				centered
				hide-footer
				size="lg"
				class="text-center">
		<b-row v-if="modalAsset">
			<b-col cols="12">
				<div class="embed-responsive embed-responsive-16by9 bg-black">
					<iframe class="embed-responsive-item" :src="src" allowfullscreen="allowfullscreen"></iframe>
				</div>
			</b-col>
			<b-col cols="12" class="py-4 px-5">
				<h2>{{ modalAsset.title }}</h2>
				<p v-html="modalAsset.description" class="m-0"></p>
			</b-col>
		</b-row>    
	</b-modal>
</template>
<script>
	export default {
		computed: {
			env() {
				return process.env
			},
			modalAsset() {
				return this.$store.getters.modalAsset
			},
			src() {
				return this.env.VUE_APP_PLAYER_SRC + '?api_key=' + this.env.VUE_APP_API_KEY + '&slug=' + this.modalAsset.slug + "&fetch=true"
			}
		}
	}
</script>
