var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { id: "pinnedAsset", cols: "12", lg: "4" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "pb-4", attrs: { cols: "12" } }, [
            _c(
              "div",
              {
                staticClass: "item",
                on: {
                  click: function($event) {
                    return _vm.open(_vm.asset)
                  }
                }
              },
              [
                _vm.activeAsset && _vm.activeAsset.id === _vm.asset.id
                  ? _c("div", { staticClass: "video" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "embed-responsive embed-responsive-16by9 bg-black"
                        },
                        [
                          _c("iframe", {
                            staticClass: "embed-responsive-item",
                            attrs: {
                              src: _vm.src,
                              allowfullscreen: "allowfullscreen"
                            }
                          })
                        ]
                      )
                    ])
                  : _c("div", { staticClass: "img" }, [
                      _vm.asset.thumbnail_url
                        ? _c("img", { attrs: { src: _vm.asset.thumbnail_url } })
                        : _c("p", { staticClass: "placeholder m-0" }, [
                            _vm._v("No image")
                          ])
                    ]),
                _c("div", { staticClass: "text" }, [
                  _c("h3", [_vm._v(_vm._s(_vm.truncate(_vm.asset.title, 45)))]),
                  _vm.asset.description
                    ? _c("p", {
                        staticClass: "m-0",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.truncate(_vm.asset.description, 100)
                          )
                        }
                      })
                    : _vm._e()
                ])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }