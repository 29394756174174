<template>
	<b-col id="pinnedAsset" cols="12" lg="4">
		<b-row>
			<b-col cols="12" class="pb-4">
				<div class="item" @click="open(asset)">
					<div class="video" v-if="activeAsset && activeAsset.id === asset.id">
						<div class="embed-responsive embed-responsive-16by9 bg-black">
							<iframe class="embed-responsive-item" :src="src" allowfullscreen="allowfullscreen"></iframe>
						</div>
					</div>
					<div class="img" v-else>
						<img :src="asset.thumbnail_url" v-if="asset.thumbnail_url"/>
						<p class="placeholder m-0" v-else>No image</p>
					</div>
					<div class="text">
						<h3>{{ truncate(asset.title, 45) }}</h3>
						<p class="m-0" v-html="truncate(asset.description, 100)"  v-if="asset.description"></p>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-col>
</template>
<script>
	export default {
		data() {
			return {
				videoSrc: null,
				isMobile: false
			}
		},
		props: {
			asset: {
				default: () => {
					return null
				}
			}
		},
		computed: {
			activeAsset() {
				return this.$store.getters.activeAsset
			},
			env() {
				return process.env
			},
			loading() {
				return this.$store.getters.loading
			},
			src() {
				return this.env.VUE_APP_PLAYER_SRC + '?api_key=' + this.env.VUE_APP_API_KEY + '&slug=' + this.asset.slug + "&fetch=true&fullscreen=true"

			}
		},
		methods: {
			open(asset) {
				if (this.isMobile) {
					this.$store.dispatch('activeAsset', asset)
					this.$store.dispatch('modalAsset', null)
				} else {
					this.$bvModal.show('asset-modal')
					this.$store.dispatch('modalAsset', asset)
					this.$store.dispatch('activeAsset', null)
				}
			},
			truncate(str, length) {
				if(this.isMobile) {
					return str
				} else {
					if(str.length > length) {
						return str.substring(0, length) + '...'
					} else {
						return str
					}
				}
			}
		},
		mounted() {
			const ua = this.$browserDetect.meta.ua
			this.isMobile = ua.includes('Mobile') ? true : false
		}
	}
</script>
