var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.category
    ? _c(
        "div",
        { attrs: { id: "category" } },
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _vm.headingAsset
                    ? _c("Asset", { attrs: { asset: _vm.headingAsset } })
                    : _vm._e(),
                  _vm.pinnedAsset
                    ? _c("PinnedAsset", { attrs: { asset: _vm.pinnedAsset } })
                    : _vm._e()
                ],
                1
              ),
              _vm.assets
                ? _c("Assets")
                : _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center",
                      attrs: { role: "status" }
                    },
                    [_c("b-spinner", { attrs: { label: "Loading..." } })],
                    1
                  ),
              _c("Modal")
            ],
            1
          )
        ],
        1
      )
    : _vm.error
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { staticClass: "text-center mt-5" }, [
                    _c("h1", { staticClass: "display-1" }, [
                      _vm._v(_vm._s(_vm.error))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }