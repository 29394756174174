var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "embed" } },
    [
      _vm.asset
        ? _c(
            "b-container",
            { staticClass: "m-0 p-0", attrs: { fluid: "" } },
            [
              _c("b-embed", {
                attrs: { type: "iframe", src: _vm.url(), allowfullscreen: "" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }