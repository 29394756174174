var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filtered && _vm.filtered.length > 0
    ? _c(
        "div",
        { attrs: { id: "assets" } },
        [
          _c(
            "b-row",
            _vm._l(_vm.filtered, function(asset) {
              return _c(
                "b-col",
                {
                  key: asset.id,
                  staticClass: "pb-4",
                  attrs: { cols: "12", lg: "4" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "item",
                      on: {
                        click: function($event) {
                          return _vm.open(asset)
                        }
                      }
                    },
                    [
                      _vm.activeAsset && _vm.activeAsset.id === asset.id
                        ? _c("div", { staticClass: "video" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "embed-responsive embed-responsive-16by9 bg-black"
                              },
                              [
                                _c("iframe", {
                                  staticClass: "embed-responsive-item",
                                  attrs: {
                                    src: _vm.getVideoSrc(_vm.activeAsset),
                                    allowfullscreen: "allowfullscreen"
                                  }
                                })
                              ]
                            )
                          ])
                        : _c("div", { staticClass: "img" }, [
                            asset.thumbnail_url
                              ? _c("img", {
                                  attrs: { src: asset.thumbnail_url }
                                })
                              : _c("p", { staticClass: "placeholder m-0" }, [
                                  _vm._v("No image")
                                ])
                          ]),
                      _c("div", { staticClass: "text" }, [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.truncate(asset.title, 45)))
                        ]),
                        asset.description
                          ? _c("p", {
                              staticClass: "m-0",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.truncate(asset.description, 100)
                                )
                              }
                            })
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }